import { OrderPaynow } from '@divit-stem/divit-orderjs'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SuccessOutlinedSvg } from '@/assets/common/success-outlined.svg'
import Icon from '@/components/Icon'
import * as CSLayout from '@/components/layout/CSLayout'
import PaddingContent from '@/components/layout/PaddingContent'
import MerchantLogo from '@/components/merchants/MerchantLogo'
import MerchantName from '@/components/merchants/MerchantName'
import MilesAndAmountValue from '@/components/miles/MilesAndAmountValue'
import MilesValue from '@/components/miles/MilesValue'
import TicketsValue from '@/components/miles/TicketsValue'
import Spacer from '@/components/Spacer'
import { ActionButtons, Line, PrimaryButton } from '@/components/ui/Shared'
import Title from '@/components/ui/Title'
import { tt } from '@/locales/format'
import { joinAddress } from '@/utils/Address'
import * as DivitMiles from '@/utils/DivitMiles'
import * as Instalments from '@/utils/Instalments'

const FullWidthPaddingContent = styled(PaddingContent).attrs({
  isFullWidth: true,
})`
  max-width: 600px;
  margin: 0 auto;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const MerchantLogoContainer = styled.div`
  text-align: center;
  margin: 0 auto;
`

const MerchantNameContainer = styled.div`
  font-weight: 600;
  text-align: center;
`

const ItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ItemTitle = styled.div`
  font-size: 0.778rem;
  font-weight: 500;
`

const ItemValue = styled.div`
  max-width: 70%;
  font-size: 0.778rem;
  font-weight: 700;
  text-align: right;
`

const CountDowntContent = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.778rem;
  font-weight: 600;
`

const PaynowSuccessScreen = ({ order, orderMiles, metadata, onNext }) => {
  const intl = useIntl()
  const history = useHistory()
  const queryParameters = new URLSearchParams(window.location.search)
  const paidby =
    process.env.REACT_APP_ENV !== 'prod' && queryParameters.get('payby')
      ? queryParameters.get('payby').toLocaleLowerCase()
      : null
  const [time, setTime] = useState(10)
  const onClickBackMerchant = () => {
    if (OrderPaynow(order).isRedirectSuccessNotAvailable()) {
      history.push('/')
    } else {
      window.location.href = order.webhookSuccess
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer)
          onClickBackMerchant()
        }
        return prevTime - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const instalment = order?.instalments[0]
  const instalmentTotalAmount =
    Instalments.getTotalOutstandingAmount(instalment)
  const milesBurned = OrderPaynow(order).getMilesBurned()
  const milesEarned = DivitMiles.getMilesEarned(orderMiles)
  const ticketsEarned = DivitMiles.getTicketsEarned(metadata)
  const partnerRef = OrderPaynow(order).getPartnerRef()

  return (
    <CSLayout.CSContainer>
      <CSLayout.CSContent>
        <FullWidthPaddingContent>
          <Spacer height="2.222rem" />
          <Icon
            renderImage={() => <SuccessOutlinedSvg />}
            width="1.736rem"
            height="1.736rem"
          />
          <Spacer height="0.889rem" />
          <Title>{tt(intl, 'payment.success')}</Title>
          <Spacer height="0.889rem" />
          <SubTitle>
            {tt(intl, 'common.congratulations')},<br />
            {tt(intl, 'payment.orderplaced')}
          </SubTitle>
          <Spacer height="1.778rem" />
          <MerchantLogoContainer>
            <MerchantLogo
              merchantID={order.merchantID}
              width="4rem"
              height="4rem"
            />
          </MerchantLogoContainer>
          <Spacer height="0.444rem" />
          <MerchantNameContainer>
            <MerchantName merchantID={order.merchantID} />
          </MerchantNameContainer>
          <Spacer height="1.778rem" />
          <ItemRow>
            <ItemTitle>{tt(intl, 'order.merchantreference')}</ItemTitle>
            <ItemValue>{partnerRef}</ItemValue>
          </ItemRow>
          <Spacer height="1.333rem" />
          <Line />
          <Spacer height="1.333rem" />
          {order.deliveryAddress && order.deliveryAddress.address1 && (
            <>
              <ItemRow>
                <ItemTitle>{tt(intl, 'order.deliverto')}</ItemTitle>
                <ItemValue>
                  {joinAddress([
                    order.deliveryAddress.address1,
                    order.deliveryAddress.address2,
                    order.deliveryAddress.district,
                    order.deliveryAddress.region,
                  ])}
                </ItemValue>
              </ItemRow>
              <Spacer height="1.333rem" />
              <Line />
              <Spacer height="1.333rem" />
            </>
          )}
          <ItemRow>
            <ItemTitle>{tt(intl, 'payment.youpaid')}</ItemTitle>
            <MilesAndAmountValue
              fontSize="1.111rem"
              currency={OrderPaynow(order, paidby).currencyConvert(
                instalmentTotalAmount.currency
              )}
              amount={
                instalmentTotalAmount.amount *
                OrderPaynow(order, paidby).moneyConvert
              }
              miles={milesBurned}
            />
          </ItemRow>
          {milesEarned > 0 && (
            <>
              <Spacer height="1.333rem" />
              <ItemRow>
                <ItemTitle>{tt(intl, 'payment.youearned')}</ItemTitle>
                <ItemValue>
                  <MilesValue
                    fontSize="0.889rem"
                    miles={milesEarned}
                    isSigned={false}
                  />
                </ItemValue>
              </ItemRow>
            </>
          )}
          {ticketsEarned > 0 && (
            <>
              {milesEarned > 0 && <Spacer height="0.886rem" />}
              {milesEarned === 0 && <Spacer height="1.333rem" />}
              <ItemRow>
                <ItemTitle>{tt(intl, 'reward.draw.ticket.label')}</ItemTitle>
                <ItemValue>
                  <TicketsValue fontSize="0.889rem" amount={ticketsEarned} />
                </ItemValue>
              </ItemRow>
            </>
          )}
          <Spacer heigh="2.222rem" />
        </FullWidthPaddingContent>
      </CSLayout.CSContent>
      <CSLayout.CSFooter>
        <FullWidthPaddingContent>
          <CountDowntContent>
            {tt(intl, 'payment.succes.returnToMerchant', { seconds: time })}...
          </CountDowntContent>
        </FullWidthPaddingContent>
        <FullWidthPaddingContent>
          <ActionButtons>
            <PrimaryButton onClick={onNext || onClickBackMerchant}>
              {tt(intl, 'common.done')}
            </PrimaryButton>
          </ActionButtons>
        </FullWidthPaddingContent>
      </CSLayout.CSFooter>
    </CSLayout.CSContainer>
  )
}

export default PaynowSuccessScreen
