import { OrderPaynow } from '@divit-stem/divit-orderjs'
import { ColorExtractor } from 'image-color-extraction'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useNetwork } from 'ahooks'
import styled from 'styled-components'

import { ReactComponent as MoreSvg } from '@/assets/common/arrow-more.svg'
import { ReactComponent as FpsWarning } from '@/assets/payment/fps-warning.svg'
import { ReactComponent as LoginNowSvg } from '@/assets/payment/login-now.svg'
import ErrorScreen from '@/components/error/ErrorScreen'
import FpsPaymentAmount from '@/components/fps/FpsPaymentAmount'
import FpsPaymentDetails from '@/components/fps/FpsPaymentDetails'
import useFps from '@/components/fps/useFps'
import PaddingContent from '@/components/layout/PaddingContent'
import Line from '@/components/Line'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import MilesValue from '@/components/miles/MilesValue'
import NormalMessageBox from '@/components/NormalMessageBox'
import PowerbyCiti from '@/components/PowerbyCiti'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import { ERROR_CODE_FPS_UNAVAILABLE } from '@/constants/errors'
import PaymentSettings from '@/constants/payment'
import { useAuth } from '@/contexts/authContext'
import { useLocale } from '@/contexts/localeContext'
import useQueryString from '@/hooks/useQueryString'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import { tt } from '@/locales/format'
import PaynowFailScreen from '@/pages/paynow/PaynowFailScreen'
import PaynowFpsUnavailableScreen from '@/pages/paynow/PaynowFpsUnavailableScreen'
import PaynowSuccessScreen from '@/pages/paynow/PaynowSuccessScreen'
import { mustReplace } from '@/redux/app/appActions'
import {
  getFpsServiceSelector,
  getFpsCitiServiceSelector,
  getFpsQRServiceSelector,
  getMerchantRateCurrencyToDVMSelector,
  merchantsSelector,
} from '@/redux/lookup/lookupSelector'
import { promptCustomerServiceModal } from '@/redux/modal/csModalActions'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import { checkEDDALimit } from '@/redux/pages/payActions'
import { verifyIdentity } from '@/redux/pin/pinActions'
import { divitMilesSelector } from '@/redux/profile/profileSelector'
import { formatFullDateTime } from '@/utils/date'
import * as Instalments from '@/utils/Instalments'
import * as Order from '@/utils/Order'
import * as PaymentUtils from '@/utils/Payment'
import { getPaymentCallbackUri, IsPaylatering } from '@/utils/Route'

import PaylaterFpsUnavailableScreen from '../paylater/PaylaterFpsUnavailableScreen'
import PaynowMilesDetails from '../paynow/PaynowMilesDetails'
import SetupPaymentModal from '../SetupPayment'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  min-height: calc(100vh - 3.2rem);
  overflow-y: auto;

  @media (max-width: 640px) {
    background-color: #fc3;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
`

const MainContent = styled.div`
  width: 100%;
  min-height: 100%;
  height: max-content;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 640px) {
    padding: 0rem 1.2rem;
  }
`
const UpperMainContent = styled.div``
const LowerMainContent = styled.div``

const NotificationContent = styled.div``

const PaymentContent = styled.div`
  padding: 0rem 1.2rem;
  background-color: #fff;

  @media (max-width: 640px) {
    padding: 0rem;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
`

const InactivityContainer = styled.div`
  font-size: 0.7rem;
  padding: 0.6rem 0.8rem;
  background-color: #e0f1ff;
  color: #048bf1;
`

const InactivityNotice = styled(PaddingContent).attrs({
  isFullWidth: true,
})`
  flex-direction: row;
  align-items: center;
`
const InactivityNoticeText = styled.div``

const OfflineNoticeContainer = styled.div`
  font-size: 0.7rem;
  padding: 0.6rem 0.8rem;
  background-color: rgba(230, 23, 26, 0.1);
  color: #e6171a;
`
const OfflineNotice = styled(PaddingContent).attrs({
  isFullWidth: true,
})`
  flex-direction: row;
  align-items: center;
`

const FpsTopNoticeContainer = styled(PaddingContent).attrs({
  isFullWidth: true,
})``

const FpsTopNotice = styled.div`
  font-size: 0.778rem;
  padding: 0.667rem 0.8rem;
  box-sizing: border-box;
  background-color: #e0f1ff;
  color: #048bf1;
  border-radius: 0.444rem;
`

const PromotionMessageDesktopVerticalContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 540px;
  flex: 0 0 35%;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
    display: none;
  }
`

const PromotionMessageMobileHorizontalContainer = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
    display: block;
    padding: 0rem;
    max-width: 100%;
  }
`

const FpsPaymentDetailsContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: '640px') {
    padding: 0rem;
  }
`

const Footer = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;
`

const LinkButton = styled.a`
  cursor: pointer;
`

const WarningIcon = styled(FpsWarning)`
  width: 36px;
  height: 36px;
  margin-right: 1rem;
`
const SloganWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`

const PayWithDivitMiles = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eff0f1;
  padding: 0.8rem 1.2rem;
  margin-top: 0rem;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  background-color: #fff;

  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;

  @media (max-width: 640px) {
    margin-top: 1.2rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }
`

const DivitMilesValue = styled(MilesValue).attrs({ type: 'yellow' })`
  & div {
    font-weight: 700;
    letter-spacing: 0.03em;
  }
`
const PayWithDivitMilesTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 700;
`

const PayWithDivitMilesLogin = styled(PayWithDivitMilesTitle)`
  color: #fc3;
`

const PayWithDivitMilesBalance = styled(PayWithDivitMilesTitle)``

const MilesBurnContainer = styled(PaddingContent)`
  padding: 0;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const OrderPayment = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { online } = useNetwork()
  const { authUserID, role } = useAuth()
  const {
    orderId,
    paymentMethod,
    instalmentId: paylaterInstalmentId,
  } = useParams()

  sessionStorage.setItem('paymentMethod', paymentMethod)

  const isPaylaterPaymentPage = !pathname.includes('/paynow/')
  const isPaylatering = IsPaylatering(pathname)
  const { currentLocale } = useLocale()
  const { mode, edda } = useQueryString()
  const isPosMode = mode && mode.toLocaleLowerCase() === 'pos'
  const paidby = null

  const payAmountRef = useRef()
  const [isEDDA] = useState(() => edda === 'true')

  const [fpsDetails, setFpsDetails] = useState()
  const [shouldConnect, setShouldConnect] = useState(false)
  const { appLink: payLink = '' } = fpsDetails || {}
  const [isPaymentFailed, setIsPaymentFailed] = useState(false)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [merchant, setMerchant] = useState()
  const [horizontalPromotionMessages, setHorizontalPromotionMessages] =
    useState(<></>)
  const [verticalPromotionMessages, setVerticalPromotionMessages] = useState(
    <></>
  )

  const { order, orderMiles, metadata, bankListFps } = useSelector(
    (s) => s.order
  )
  const { profile } = useSelector((s) => s.profile)

  const merchants = useSelector(merchantsSelector)
  const rateCurrencyToDVM = useSelector(getMerchantRateCurrencyToDVMSelector)
  const instalmentId = isPaylaterPaymentPage
    ? paylaterInstalmentId
    : order?.instalments[0].instalmentID
  const {
    fetchOrder: fetchOrderFastPaynow,
    generateFpsLink: generateFpsLinkFastPaynow,
    refreshOrder: refreshOrderFastPaynow,
  } = useSelector((s) => s.paynowFastFps)

  const {
    fetchOrder: fetchOrderPaynow,
    generateFpsLink: generateFpsLinkPaynow,
    payByEDDA,
    refreshOrder: refreshOrderPaynow,
    cancelOrder,
    linkOrder,
  } = useSelector((s) => s.paynow)

  const { updateProfileAdditional } = useSelector((s) => s.updateProfile)
  const divitMiles = useSelector(divitMilesSelector)
  const {
    fetchOrder: fetchOrderPaylater,
    fpsMethod,
    disableManualFPS,
    instalmentNum,
    payByEDDA: paylaterPayByEDDA,
    generateFpsLink: generateFpsLinkPaylater,
    refreshOrder: refreshOrderPaylater,
  } = useSelector((s) => s.payWithFps)
  const fpsService = useSelector(getFpsServiceSelector)
  const fpsQRService = useSelector(getFpsQRServiceSelector)
  const isFpsQRAvailable = !fpsQRService
  const fpsCitiService = useSelector(getFpsCitiServiceSelector)
  const isFpsCitiServiceUnavailable = !!fpsCitiService
  const miscConfigs = useSelector((s) => s.app?.miscconfigs || [])

  const { inactivity, clearManualInactiveTimer } = useFps()

  const fetchOrder = useMemo(
    () =>
      isPaylaterPaymentPage
        ? fetchOrderPaylater
        : fetchOrderFastPaynow.data
        ? fetchOrderFastPaynow
        : fetchOrderPaynow,
    [fetchOrderFastPaynow, fetchOrderPaynow, fetchOrderPaylater]
  )
  const generateFpsLink = useMemo(
    () =>
      isPaylaterPaymentPage
        ? generateFpsLinkPaylater
        : generateFpsLinkFastPaynow.data
        ? generateFpsLinkFastPaynow
        : generateFpsLinkPaynow,
    [generateFpsLinkFastPaynow, generateFpsLinkPaynow, generateFpsLinkPaylater]
  )
  const refreshOrder = useMemo(
    () =>
      isPaylaterPaymentPage
        ? refreshOrderPaylater
        : refreshOrderFastPaynow.data
        ? refreshOrderFastPaynow
        : refreshOrderPaynow,
    [refreshOrderFastPaynow, refreshOrderPaynow, refreshOrderPaylater]
  )

  const isMilesSpent =
    order?.instalments[instalmentNum - 1].miles &&
    order?.instalments[instalmentNum - 1].miles > 0

  const clearAllTimers = () => {
    clearManualInactiveTimer?.()
  }

  const refreshOrderStatus = () => {
    if (isPaylaterPaymentPage) {
      dispatch({
        type: 'payWithFps/fpsRefreshOrder',
        payload: { orderId },
      })
    } else {
      dispatch({
        type: 'paynowFastFps/fpsRefreshOrder',
        payload: { orderId },
      })
    }
  }

  const refreshFpsCode = () => {
    const instrumentCode = paymentMethod
    if (isPaylaterPaymentPage) {
      const callbackUri = getPaymentCallbackUri({
        pathname,
        orderId,
        instalmentId,
      })
      dispatch({
        type: 'payWithFps/generateFpsLink',
        payload: { orderId, instalmentId, callbackUri, paymentMethod },
      })
    } else {
      dispatch({
        type: 'paynowFastFps/generateFpsLink',
        payload: { orderId, instalmentId, instrumentCode },
      })
    }
  }

  const doPayByEDDA = (pin) => {
    if (!isPaylaterPaymentPage) {
      dispatch({
        type: 'paynow/payByEDDA',
        payload: { pin, orderId, instalmentId },
      })
    } else {
      dispatch({
        type: 'payWithFps/payByEDDA',
        payload: { pin, orderId, instalmentId },
      })
    }
  }

  const handlePayByEDDAFailed = async () => {
    await dispatch(
      promptConfirmModal({
        title: tt(intl, 'common.aiyah'),
        content: tt(intl, 'paynow.edda.failure'),
        closable: false,
      })
    )
    dispatch(mustReplace(pathname))
  }

  const goPaymentFailure = () => {
    if (isPaylatering) {
      if (order?.webhook_failure) {
        window.location.href = order.webhook_failure
      } else {
        history.push('/home')
      }
    } else {
      dispatch({ type: 'redirect/scheduleReview', payload: { order } })
    }
  }

  const startPayByEddaFlow = async () => {
    const handleValidFailed = () => {
      history.replace(pathname)
      refreshFpsCode()
    }
    try {
      const currentInstalment = order?.instalments.find(
        (instalment) => instalment.instalmentID === instalmentId
      )
      const { amount } =
        Instalments.getTotalOutstandingAmount(currentInstalment)
      const hasValidLimit = await dispatch(checkEDDALimit({ amount }))
      if (!hasValidLimit) {
        handleValidFailed()
        return
      }
      const { isSuccess, pin } = await dispatch(
        verifyIdentity({ isForce: true })
      )
      if (isSuccess) {
        doPayByEDDA(pin)
      } else {
        handleValidFailed()
        return
      }
      history.replace(pathname)
    } catch (ex) {
      handlePayByEDDAFailed()
    }
  }

  const getSocketUrl = useCallback(
    () =>
      new Promise((resolve) => {
        if (shouldConnect) {
          resolve(
            `${process.env.REACT_APP_WS_HOST_URL}/ws/customers/${instalmentId}`
          )
        }
      }),
    [shouldConnect]
  )

  const { lastJsonMessage, readyState } = useWebSocket(
    getSocketUrl,
    {
      share: true,
      shouldReconnect: () => true,
      reconnectAttempts: 30,
      reconnectInterval: 3000,
    },
    shouldConnect
  )

  useEffect(() => {
    if (!shouldConnect) return
    if (!online) {
      console.log('offline, set interval refresh order status')
      setNeedRefresh(true)
    } else if (needRefresh) {
      console.log('back online, clear interval')
      setNeedRefresh(false)
      setShouldConnect(false) // reset shouldConnect
      setTimeout(() => {
        refreshOrderStatus()
        setShouldConnect(true)
      }, 2000)
    }
  }, [online, shouldConnect, needRefresh])

  // updated profile additional
  useEffect(() => {
    if (updateProfileAdditional.isSuccess) {
      sessionStorage.setItem(`reviewing-${orderId}`, true)
      refreshOrderStatus()
    }
  }, [updateProfileAdditional])

  useEffect(() => {
    if (orderId && instalmentId && payLink) {
      setShouldConnect(true)
    }
  }, [orderId, instalmentId, payLink])

  // get order when mount
  useEffect(() => {
    // if this order is transfered, go to paynow
    if (isPaylaterPaymentPage) {
      dispatch({
        type: 'payWithFps/fetchOrder',
        payload: { orderId },
      })
    } else {
      const isTransfered =
        sessionStorage.getItem('transfered_order') === orderId
      if (isTransfered) {
        if (authUserID && role === 'enduser') {
          dispatch({ type: 'paynow/linkOrder', payload: { orderId } })
        } else {
          dispatch({
            type: 'paynow/fetchOrder',
            payload: { orderId },
          })
        }
      } else {
        dispatch({
          type: 'paynowFastFps/fetchOrder',
          payload: { orderId },
        })
      }
    }
    dispatch({
      type: 'app/miscConfig',
      payload: { configType: 'payment_ui', language: currentLocale },
    })
    dispatch({
      type: 'fetchBankListFps',
    })
    dispatch({
      type: 'payWithFps/setOrderID',
      payload: {
        orderID: orderId,
        instalmentID: isPaylaterPaymentPage ? instalmentId : '',
        paylater: isPaylaterPaymentPage,
      },
    })
    return () => {
      clearAllTimers()
      setShouldConnect(false)
      dispatch({ type: 'paynow/reset' })
      dispatch({ type: 'paynowFastFps/reset' })
      dispatch({ type: 'payWithFps/reset' })
    }
  }, [])

  // Run when the connection state (readyState) changes
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      console.log('connected', readyState)
    }
    if (readyState === ReadyState.CLOSED) {
      console.log('disconnected', readyState)
      refreshOrderStatus()
    }
  }, [readyState])

  useEffect(() => {
    switch (lastJsonMessage?.name) {
      case 'order-payment-updated':
        refreshOrderStatus()
        break
      default:
        console.log('recv message', lastJsonMessage)
    }
  }, [lastJsonMessage])

  useEffect(() => {
    if (linkOrder.isSuccess) {
      dispatch({
        type: 'paynow/fetchOrder',
        payload: { orderId },
      })
    }
  }, [linkOrder])

  useEffect(() => {
    if (isFpsCitiServiceUnavailable) {
      const endAt = formatFullDateTime(moment.unix(fpsCitiService.periodEnd))
      dispatch(
        promptConfirmModal({
          content: tt(intl, 'common.fpscitimaintenance.msg', { endAt }),
          closable: false,
        })
      )
    }
  }, [isFpsCitiServiceUnavailable])

  useEffect(() => {
    if (!isFpsQRAvailable) {
      dispatch({
        type: 'payWithFps/setFpsMethod',
        payload: { fpsMethod: PaymentSettings.PAYMENT_MODE_FPSMANUAL },
      })
    }
  }, [isFpsQRAvailable])

  useEffect(() => {
    if (fpsService) {
      history.replace(`/maintenance`)
    }
  }, [fpsService])

  useEffect(() => {
    if (fpsCitiService) {
      const endAt = formatFullDateTime(moment.unix(fpsCitiService.periodEnd))
      dispatch(
        promptConfirmModal({
          content: tt(intl, 'common.fpscitimaintenance.msg', { endAt }),
          closable: false,
        })
      )
    }
  }, [fpsCitiService])

  const setPaymentMethod = ({ currency, amount }) => {
    dispatch({
      type: 'payWithFps/setOrderCurrency',
      payload: {
        currency,
        paymentMethod,
      },
    })
    switch (currency) {
      case PaymentSettings.CURRENCY_HKD:
        if (amount >= 10000 * 100) {
          dispatch({
            type: 'payWithFps/setFpsMethod',
            payload: { fpsMethod: PaymentSettings.PAYMENT_MODE_FPSMANUAL },
          })
        } else {
          dispatch({
            type: 'payWithFps/setFpsMethod',
            payload: { fpsMethod: PaymentSettings.PAYMENT_MODE_FPSQRCODE },
          })
        }
        break
      case PaymentSettings.CURRENCY_THB:
        dispatch({
          type: 'payWithFps/setFpsMethod',
          payload: { fpsMethod: PaymentSettings.PAYMENT_MODE_PROMPTPAYQR },
        })
        break
      case PaymentSettings.CURRENCY_SGD:
        dispatch({
          type: 'payWithFps/setFpsMethod',
          payload: { fpsMethod: PaymentSettings.PAYMENT_MODE_SGPAYNOWQR },
        })
        break
      default:
    }
  }

  useUpdateEffect(() => {
    if (fetchOrder.isSuccess) {
      const newOrder = fetchOrder.data
      const { merchantID } = newOrder
      const mer = merchants.find((m) => m.merchantID === merchantID)
      setMerchant(mer)

      if (isPaylaterPaymentPage) {
        const { instalments } = newOrder
        const currentInstalment = instalments.find(
          (instalment) => instalment.instalmentID === instalmentId
        )
        const instalmentTotalAmount =
          Instalments.getTotalOutstandingAmount(currentInstalment)

        setPaymentMethod(instalmentTotalAmount)
        dispatch({
          type: 'payWithFps/setSeriesNum',
          payload: {
            instalmentNum: currentInstalment.series,
          },
        })
        if (!payLink) {
          if (!generateFpsLink.isLoading) {
            refreshFpsCode()
          }
        }
        if (
          Instalments.isPaymentInstrumentProcessing(currentInstalment, 'edda')
        ) {
          // startRefreshOrderTimer()
        } else if (isEDDA) {
          startPayByEddaFlow()
        }
      } else {
        // redirect to pay now if order has miles burned
        if (fetchOrder.data.milesBurned > 0) {
          history.replace(`/pay/${paymentMethod}/paynow/${orderId}`)
          return
        }
        const instalmentTotalAmount = OrderPaynow(
          fetchOrder.data,
          paidby
        ).getTotalOutstandingAmount()
        const instalmentTotalAmountDefault = OrderPaynow(
          fetchOrder.data
        ).getTotalOutstandingAmount()

        setPaymentMethod(instalmentTotalAmountDefault)
        payAmountRef.current = instalmentTotalAmount.amount

        if (!payLink) {
          if (!generateFpsLink.isLoading) {
            refreshFpsCode()
          }
        }
        if (newOrder && !OrderPaynow(newOrder, paidby).isEnd()) {
          const fxs = (newOrder?.financialTransactions || []).filter(
            (fx) =>
              fx.paidBy === 'edda' &&
              ['pending', 'requested', 'completed'].indexOf(fx.status) >= 0
          )
          if (fxs && fxs.length > 0) {
            console.log('waiting for edda payment complete')
          } else if (isEDDA) {
            startPayByEddaFlow()
          }
        }
      }
    }
  }, [fetchOrder])

  // refresh fps when fps timeout
  useUpdateEffect(() => {
    if (generateFpsLink.isSuccess) {
      if (isPaylaterPaymentPage) {
        const { instalments, partnerid } = order
        const mer = merchants.find((m) => m.merchantID === partnerid)
        setMerchant(mer)
        dispatch({
          type: 'payWithFps/setDisableManualFps',
          payload: { disableManualFPS: mer.disableManualFPS },
        })
        const currentInstalment = instalments.find(
          (instalment) => instalment.instalmentID === instalmentId
        )
        const instalmentTotalAmount = Instalments.getOutstandingAmount(
          currentInstalment,
          true
        )

        dispatch({
          type: 'gtm/sendEvent',
          payload: {
            event: 'add_payment_info',
            userId: profile.customerID,
            orderId: order.orderid,
            ecommerce: Order.getGA4EcommercePaymentPayload(
              order,
              instalmentTotalAmount?.amount,
              'fps'
            ),
          },
        })
        setFpsDetails(PaymentUtils.getFpsDetails(generateFpsLink.data))
      } else {
        const { data } = generateFpsLink
        if (order) {
          const { merchantID } = order
          const mer = merchants.find((m) => m.merchantID === merchantID)
          setMerchant(mer)
          dispatch({
            type: 'payWithFps/setDisableManualFps',
            payload: { disableManualFPS: mer.disableManualFPS },
          })
        }
        // TODO: GA4
        setFpsDetails(PaymentUtils.getFpsDetails(data))
      }
    }
  }, [generateFpsLink])

  // if edda is set, refresh long polling order status
  useEffect(async () => {
    if (payByEDDA?.isSuccess || paylaterPayByEDDA?.isSuccess) {
      // return startRefreshOrderTimer()
    }
    if (payByEDDA?.isError || paylaterPayByEDDA?.isFailed) {
      handlePayByEDDAFailed()
    }
    return () => {}
  }, [payByEDDA, paylaterPayByEDDA])

  // check current order status is invalid paynow
  useUpdateEffect(() => {
    if (refreshOrder.isSuccess && !isPaylaterPaymentPage) {
      if (OrderPaynow(refreshOrder.data).isEnd()) {
        clearAllTimers()
        // display success / failed screen
      } else if (OrderPaynow(refreshOrder.data).isFailed(moment)) {
        setIsPaymentFailed(true)
        // dispatch failed screen => refresh fps code
      } else {
        const { amount } = OrderPaynow(
          refreshOrder.data,
          paidby
        ).getTotalOutstandingAmount()
        if (amount !== payAmountRef.current && amount > 0) {
          refreshFpsCode()
        }
        payAmountRef.current = amount
      }
    }
  }, [refreshOrder])

  // check current order status is invalid paylater
  useEffect(() => {
    if (refreshOrder.isSuccess && isPaylaterPaymentPage) {
      const { data } = refreshOrder

      // check order is expired, cancelled in checkout
      if (Order.isExpired(data) || Order.isCancelled(data)) {
        if (isPaylatering) {
          history.push(
            `/paylater/payment/check/${data.orderid}/${instalmentId}`
          )
          return
        }
      }

      // check payment status
      const { orderid, instalments } = data
      const instalment = instalments.find(
        (i) => i.instalmentID === instalmentId
      )
      const status = Instalments.getPaymentStatus(instalment)
      if (['completed', 'failed'].indexOf(status.toLowerCase()) >= 0) {
        if (isPaylatering) {
          history.push(`/paylater/payment/status/${orderid}/${instalmentId}`)
        } else {
          history.push(`/payment/status/${orderid}/${instalmentId}`)
        }
      }
    }
  }, [refreshOrder])

  useUpdateEffect(() => {
    if (
      (cancelOrder.isSuccess || cancelOrder.isError) &&
      !isPaylaterPaymentPage
    ) {
      if (OrderPaynow(order, paidby).isRedirectFailureNotAvailable()) {
        history.push('/')
      } else {
        window.location = order.webhookFailure
      }
    }
  }, [cancelOrder])

  const getPromotionImage = (m) => {
    if (m?.linkURL) {
      return (
        <a href={m?.linkURL} aria-label="promotion">
          <img
            src={m?.imageURL}
            alt=""
            style={{ width: '100%', display: 'block' }}
          />
        </a>
      )
    }
    return (
      <img
        src={m?.imageURL}
        alt=""
        style={{ width: '100%', display: 'block' }}
      />
    )
  }

  useEffect(() => {
    if (miscConfigs) {
      const messages = miscConfigs.filter((m) => m.configType === 'payment_ui')
      Promise.all(
        messages.map(async (m) => {
          if (m.content?.imageURL) {
            const extractor = new ColorExtractor()
            const result = await extractor
              .extractColor(m.content?.imageURL)
              .then(() => {
                const colors = extractor.chooseReadableColor()
                return {
                  ID: m.ID,
                  color: colors[0],
                  positionID: m.content?.positionID,
                  linkURL: m.content?.linkURL,
                  imageURL: m.content?.imageURL,
                }
              })
            return result
          }
          return {}
        })
      ).then((msg) => {
        if (msg.length > 0) {
          setHorizontalPromotionMessages(
            <>
              {msg
                .filter((m) => m.positionID === 'mobileHorizontal')
                .map((m) => (
                  <PromotionMessageMobileHorizontalContainer
                    key={m.ID}
                    style={{
                      backgroundColor: m.color,
                    }}
                  >
                    {getPromotionImage(m)}
                  </PromotionMessageMobileHorizontalContainer>
                ))}
            </>
          )
          setVerticalPromotionMessages(
            <>
              {msg
                .filter((m) => m.positionID === 'desktopVertical')
                .map((m) => (
                  <PromotionMessageDesktopVerticalContainer
                    key={m.ID}
                    style={{
                      backgroundColor: m.color,
                    }}
                  >
                    {getPromotionImage(m)}
                  </PromotionMessageDesktopVerticalContainer>
                ))}
            </>
          )
        }
      })
    }
  }, [miscConfigs])

  // updated profile additional loading
  if (
    (updateProfileAdditional.isSuccess && fetchOrder.isLoading) ||
    (sessionStorage.getItem(`reviewing-${orderId}`) === 'true' &&
      fetchOrder.isLoading)
  ) {
    return <LoadingScreen title="finishing payment, please wait…" />
  }

  // first get order error
  if (fetchOrder.isError)
    return isPaylaterPaymentPage ? (
      <Redirect to={`/paylater/payment/status/${orderId}/${instalmentId}`} />
    ) : (
      <PaynowFailScreen order={order} error={fetchOrder.error} />
    )

  if (fetchOrder.isLoading) return <LoadingScreen loading />

  if (!fetchOrder.data) return <LoadingScreen loading />

  if (!order || !merchant) return <LoadingScreen loading />

  if (order.orderid !== orderId && isPaylaterPaymentPage) return <></>

  if (
    (Order.isExpired(order) || Order.isCancelled(order)) &&
    isPaylaterPaymentPage
  ) {
    if (isPaylatering) {
      return (
        <Redirect to={`/paylater/payment/check/${orderId}/${instalmentId}`} />
      )
    }
    return <Redirect to={`/payment/check/${orderId}/${instalmentId}`} />
  }

  if (generateFpsLink.isLoading) return <LoadingModal loading />

  const { instalments } = order

  const currentInstalment = instalments.find(
    (instalment) => instalment.instalmentID === instalmentId
  )

  const isHasReviewingPayment = isPaylaterPaymentPage
    ? currentInstalment?.financialTx &&
      currentInstalment.financialTx.some(
        (tx) =>
          tx.transaction_type === 'payment' &&
          tx.transaction_status === 'reviewing'
      ) &&
      sessionStorage.getItem(`reviewing-${orderId}`) !== 'true'
    : order?.financialTransactions &&
      order.financialTransactions.some(
        (tx) =>
          tx.transactionType === 'payment' &&
          tx.transactionStatus === 'reviewing'
      ) &&
      sessionStorage.getItem(`reviewing-${orderId}`) !== 'true'

  // show order success
  const isOrderSuccess = (data) => {
    if (!data) return false
    return OrderPaynow(data).isCompleted()
  }

  if (
    !isPaylaterPaymentPage &&
    ((order && isOrderSuccess(order)) || isOrderSuccess(refreshOrder.data))
  ) {
    clearAllTimers()
    sessionStorage.removeItem('paynow-token')
    return (
      <PaynowSuccessScreen
        order={order || refreshOrder}
        orderMiles={orderMiles}
        metadata={metadata}
      />
    )
  }

  const isEDDAProcessing = isPaylaterPaymentPage
    ? Instalments.isPaymentInstrumentProcessing(currentInstalment, 'edda')
    : OrderPaynow(order).isPaymentInstrumentProcessing('edda')

  const isPaidByEDDA =
    isEDDA &&
    ((payByEDDA && (payByEDDA.isLoading || payByEDDA.isSuccess)) ||
      (paylaterPayByEDDA &&
        (paylaterPayByEDDA.isLoading || paylaterPayByEDDA.isSuccess)))

  if (isEDDAProcessing || isPaidByEDDA) {
    return (
      <LoadingScreen
        title={tt(intl, 'paynow.eddawaitingtitle')}
        content={tt(intl, 'paynow.eddawaitingmsg')}
      />
    )
  }

  if (!payLink) {
    return <LoadingModal loading />
  }

  // refresh order error
  const isOrderInvalid = (data) => {
    if (!data) return false
    return OrderPaynow(data).isExpired() || OrderPaynow(data).isCancelled
  }

  const onClickContactus = () => {
    dispatch(promptCustomerServiceModal())
  }

  const onClickBurn = () => {
    if (isPaylaterPaymentPage) {
      history.push(`/paylater/${orderId}/${paylaterInstalmentId}/burn`)
    } else {
      history.push(`/paynow/${orderId}/burn`)
    }
  }

  // fps maintenance screen
  if (
    generateFpsLink.isError &&
    generateFpsLink.error?.code === ERROR_CODE_FPS_UNAVAILABLE
  ) {
    const { periodEnd = 0 } = generateFpsLink.error?.data || {}
    return isPaylaterPaymentPage ? (
      <PaylaterFpsUnavailableScreen order={order} periodEnd={periodEnd} />
    ) : (
      <PaynowFpsUnavailableScreen order={order} periodEnd={periodEnd} />
    )
  }

  if (generateFpsLink.error && isPaylaterPaymentPage) {
    // kyc verify
    if (generateFpsLink.error.code === 6704) {
      return (
        <Container>
          <PaddingContent>
            <Spacer height="2.222rem" />
            <NormalMessageBox
              buttonText={tt(intl, 'button.verify')}
              onClick={() => {
                history.push('/paylater/kyc', {
                  nextPage: pathname,
                })
              }}
            >
              {tt(intl, 'paylater.payment.verifykyc')}
            </NormalMessageBox>
          </PaddingContent>
        </Container>
      )
    }
    // tnc not done
    if (generateFpsLink.error.code === 7016) {
      return (
        <Redirect to={`/paylater/payment/check/${orderId}/${instalmentId}`} />
      )
    }
    // already paid
    if (generateFpsLink.error.code === 7109) {
      if (isPaylatering) {
        return (
          <Redirect
            to={`/paylater/payment/status/${orderId}/${instalmentId}`}
          />
        )
      }
      return <Redirect to={`/payment/status/${orderId}/${instalmentId}`} />
    }
    return (
      <ErrorScreen
        title={tt(intl, 'common.aiyah')}
        content={intl.formatMessage({ id: 'error.action.payment' })}
        error={generateFpsLink.error}
        onOk={goPaymentFailure}
        okText={intl.formatMessage({ id: 'button.back' })}
      />
    )
  }

  // show order fail
  if (
    !isPaylaterPaymentPage &&
    (isOrderInvalid(order) ||
      isOrderInvalid(refreshOrder.data) ||
      refreshOrder.isError ||
      generateFpsLink.isError)
  ) {
    clearAllTimers()
    sessionStorage.removeItem('paynow-token')
    return (
      <PaynowFailScreen
        order={order}
        error={refreshOrder.error || generateFpsLink.error}
      />
    )
  }

  // show payment failed
  if (isPaymentFailed) {
    clearAllTimers()
    return (
      <PaynowFailScreen
        order={order}
        onRetry={() => {
          refreshFpsCode()
          setTimeout(() => {
            setIsPaymentFailed(false)
          }, 250)
        }}
      />
    )
  }

  const onClickLogin = () => {
    // coz user didnt login yet, go payment page to deterimine where go next
    sessionStorage.setItem('return_url', `/paynow/${orderId}/burn`)
    dispatch({ type: 'order/setIsFromFastPaynow', payload: true })
    history.push(`/login/redirect`)
  }

  const instalmentTotalWithoutBurn = isPaylaterPaymentPage
    ? Instalments.getOutstandingAmount(currentInstalment, false)
    : OrderPaynow(order, paidby).getOutstandingAmount()

  const getMilesBurned = isPaylaterPaymentPage
    ? Instalments.getTotalMilesPaidAmount(currentInstalment).amount > 0
      ? 0
      : Instalments.getMilesBurned(currentInstalment)
    : OrderPaynow(order, paidby).getPaidByMilesAmount().amount > 0
    ? 0
    : OrderPaynow(order, paidby).getMilesBurned()

  const isShowMilesDetails = getMilesBurned > 0
  if (isShowMilesDetails && !rateCurrencyToDVM) return <LoadingModal loading />

  const amountToSave = isShowMilesDetails
    ? getMilesBurned * 100 * rateCurrencyToDVM.buy
    : 0
  const amountToSpend = instalmentTotalWithoutBurn.amount - amountToSave

  const spendAmount = {
    currency: instalmentTotalWithoutBurn.currency,
    amount: amountToSpend,
  }

  return (
    <Container>
      <Content>
        {verticalPromotionMessages}
        <MainContent>
          <UpperMainContent>
            <NotificationContent>
              {!online && shouldConnect && (
                <>
                  <OfflineNoticeContainer>
                    <OfflineNotice>
                      {tt(intl, 'common.network.offline')}
                    </OfflineNotice>
                  </OfflineNoticeContainer>
                </>
              )}
              {horizontalPromotionMessages}
              {isFpsCitiServiceUnavailable && isPaylaterPaymentPage && (
                <>
                  <FpsTopNoticeContainer>
                    <FpsTopNotice>
                      {tt(intl, 'common.fpscitimaintenance.msg', {
                        endAt: formatFullDateTime(
                          moment.unix(fpsCitiService.periodEnd)
                        ),
                      })}
                    </FpsTopNotice>
                  </FpsTopNoticeContainer>
                </>
              )}
              {isPaylaterPaymentPage && (
                <FpsTopNoticeContainer>
                  <FpsTopNotice>
                    {tt(intl, 'paylater.hkidmatches')}
                  </FpsTopNotice>
                  <Spacer height="0.667rem" />
                </FpsTopNoticeContainer>
              )}
              {inactivity && (
                <InactivityContainer>
                  <InactivityNotice>
                    <div>
                      <WarningIcon />
                      <Spacer width="0.5rem" />
                    </div>
                    <InactivityNoticeText>
                      {tt(intl, 'fps.inactivity')}&nbsp;
                      <LinkButton onClick={onClickContactus}>
                        <b>{tt(intl, 'fps.contactcs')}</b>
                      </LinkButton>
                    </InactivityNoticeText>
                  </InactivityNotice>
                </InactivityContainer>
              )}
              {isFpsCitiServiceUnavailable && !isPaylaterPaymentPage && (
                <>
                  <Spacer height="0.8rem" />
                  <FpsTopNoticeContainer>
                    <FpsTopNotice>
                      {tt(intl, 'common.fpscitimaintenance.msg', {
                        endAt: formatFullDateTime(
                          moment.unix(fpsCitiService?.periodEnd)
                        ),
                      })}
                    </FpsTopNotice>
                  </FpsTopNoticeContainer>
                  <Spacer height="0.8rem" />
                </>
              )}
            </NotificationContent>
            <PaymentContent>
              <FpsPaymentAmount
                instalmentAmount={spendAmount}
                milesToEarn={order.milesToEarn}
                merchant={merchant}
                merchantRef={order.merchantRef}
              />
              <FpsPaymentDetailsContainer>
                <FpsPaymentDetails
                  generateFpsLink={generateFpsLink}
                  type={fpsMethod}
                  fpsDetails={fpsDetails}
                  bankListFps={bankListFps}
                  isPosMode={isPosMode}
                  disableManualFPS={disableManualFPS && isFpsQRAvailable}
                />
              </FpsPaymentDetailsContainer>
              <Spacer height="0.8rem" />
              <Footer>
                <SloganWrapper>
                  <PowerbyCiti />
                </SloganWrapper>
              </Footer>
              <Spacer height="1.2rem" />
              {isShowMilesDetails && (
                <MilesBurnContainer>
                  <Line />
                  <Spacer height="1.222rem" />
                  <Title>{tt(intl, 'spend.spendmiles')}</Title>
                  <Spacer height="0.889rem" />
                  <SubTitle>{tt(intl, 'spend.spendmilesandsave')}</SubTitle>
                  <PaynowMilesDetails
                    orderAmount={instalmentTotalWithoutBurn}
                    milesBurned={getMilesBurned}
                    merchantID={order.merchantID}
                    currency={order?.currency || order?.totalAmount?.currency}
                  />
                </MilesBurnContainer>
              )}
            </PaymentContent>
          </UpperMainContent>
          <LowerMainContent>
            {divitMiles.balance > 0 && !isMilesSpent && (
              <PayWithDivitMiles onClick={onClickBurn}>
                <PayWithDivitMilesTitle>
                  {tt(intl, 'payment.paywithmiles')}
                </PayWithDivitMilesTitle>
                <PayWithDivitMilesBalance>
                  <DivitMilesValue
                    miles={divitMiles.balance}
                    isSigned={false}
                    fontSize="1rem"
                    style={{ marginRight: '0.4rem' }}
                  />
                  <MoreSvg />
                </PayWithDivitMilesBalance>
              </PayWithDivitMiles>
            )}
            {!authUserID && !isPosMode && (
              <PayWithDivitMiles onClick={onClickLogin}>
                <PayWithDivitMilesTitle>
                  {tt(intl, 'payment.paywithmiles')}
                </PayWithDivitMilesTitle>
                <PayWithDivitMilesLogin>
                  {tt(intl, 'payment.loginnow')}
                  <LoginNowSvg
                    style={{
                      marginLeft: '0.3rem',
                      marginTop: '0.1rem',
                      marginBottom: '-0.1rem',
                    }}
                  />
                </PayWithDivitMilesLogin>
              </PayWithDivitMiles>
            )}
          </LowerMainContent>
        </MainContent>
      </Content>
      <SetupPaymentModal
        isOpen={isHasReviewingPayment || false} // change to test profile additional modal
        orderID={orderId}
        orderType={isPaylaterPaymentPage ? 'paylater' : 'paynow'}
        instalmentID={instalmentId}
      />
    </Container>
  )
}

export default OrderPayment
